import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import AccountScoreCard from '../Components/Account/AccountScoreCard';
import AccountSpendCard from '../Components/Account/AccountSpendCard';
import TrainingManagerWidget from '../Components/Trainings/TrainingMangerWidget';
import TrainingIndividualWidget from '../Components/Trainings/TrainingIndividualWidget';
import NetworkCard from '../Components/Network/NetworkCard';
import Alert from '../Components/Common/Alert';
import Loader from '../Components/Loader';
import AccountWidget from '../Components/Account/AccountWidget';
import AwsHealthEventWidget from '../Components/Vendor/HealthEventWidget';
import { Context } from '../Context/MenuContext';
import { Context as PageContext } from '../Context/PageContext';
import AwsAzureWidget from '../Components/AwsAzure/AwsAzureWidget';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';
import { getFeedbacks } from '../store/actions/feedbackAction';
import Toast from '../Components/Common/Toast';

const Home = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [accountLoading, setAccountLoading] = useState(true);
  const [aWSHealthLoading, setAWSHealthLoading] = useState(true);
  const [errorMessageAccountSummry, setErrorMessageAccountSummry] = useState('');
  const [showAccSumErr, setShowAccSumErr] = useState(false);
  const [errorMessageAccountList, setErrorMessageAccountList] = useState('');
  const [showAccListError, setShowAccListErr] = useState(false);
  const [errorMessageTraining, setErrorMessageTraining] = useState('');
  const [showTrainingErr, setShowTrainingErr] = useState(false);
  const [errorMessageAWSHealth, setErrorMessageAWSHealth] = useState('');
  const [showAwsHealthErr, setShowAwsHealthErr] = useState(false);
  const [trainingLoading, setTrainingLoading] = useState(true);
  const [accountSummary, setAccountSummary] = useState({});
  const [accountListInfo, setAccountListInfo] = useState([]);
  const [accountType, setAccountType] = useState('');
  const [isManager, setIsManager] = useState(undefined);
  const [trainingInfo, setTrainingInfo] = useState([]);
  const [awsTrainingInfo, setAwsTrainingInfo] = useState([]);
  const [azureTrainingInfo, setAzureTrainingInfo] = useState([]);
  const [awsHealthEventList, setAwsHealthEventList] = useState([]);
  const [showToast, setShowToast] = useState(false);

  const prevStoreDataRef = useRef();

  const userFullName = user && `${user.firstName} ${user.lastName}`;
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { homePage } = pageContextState;

  const dispatch = useDispatch();
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu] = useContext(Context);

  const feedbackList = useSelector((state) => state.feedbackList);
  const { feedbacks } = feedbackList;

  // Get LoggedIn User feedback
  useEffect(() => {
    const apiUrl = '/api/feedback/GetUserFeedback';
    dispatch(getFeedbacks(apiUrl));
  }, [dispatch]);

  useEffect(() => {
    const storedValue = localStorage.getItem('prevStoreData');
    if (storedValue) {
      prevStoreDataRef.current = JSON.parse(storedValue);
    }
  }, []);

  useEffect(() => {
    if (prevStoreDataRef.current !== feedbacks) {
      setShowToast(!feedbacks);
      localStorage.setItem('prevStoreData', JSON.stringify(feedbacks));
      prevStoreDataRef.current = feedbacks;
    }
  }, [feedbacks]);

  // Get user login details
  useEffect(() => {
    if (homePage === '') {
      setPageContextState({
        ...pageContextState,
        homePage: 'home',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/home`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [homePage]);

  function getAccountSummary() {
    axios
      .get('/api/account/summary')
      .then((response) => {
        setAccountSummary(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          setErrorMessageAccountSummry(
            `Account Key Metrics Widget: ${e.message}`,
          );
          setShowAccSumErr(true);
        }
        setLoading(false);
      });
  }

  function getAccountListInfo() {
    // Get Account List that user owns
    axios
      .get(`/api/account/search?t=Owner&q=${userFullName}`)
      .then((response) => {
        if (response.data.matchCount > 0) {
          const acctOwned = response.data.items.map((acnt) => ({
            accountName: acnt.name,
            accountNumber: acnt.number,
          }));
          setAccountType('own');
          setAccountListInfo(acctOwned);
          setAccountLoading(false);
        } else {
          // Get Account List that user has access to
          axios
            .get(`/api/people/${user.employeeId}/accounts`)
            .then((response) => {
              if (response.data.length > 0) {
                const acctAcess = response.data.map((acnt) => ({
                  accountName: acnt.accountName,
                  accountNumber: acnt.accountNumber,
                }));
                setAccountType('access');
                setAccountListInfo(acctAcess);
              } else {
                setAccountType('none');
                setAccountListInfo({ none: 'none' });
              }
              setAccountLoading(false);
            })
            .catch((e) => {
              if (e.name !== 'AbortError') {
                setErrorMessageAccountList(`Account List Widget: ${e.message}`);
                setShowAccListErr(true);
              }
              setAccountLoading(false);
            });
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          setErrorMessageAccountList(`Account List Widget: ${e.message}`);
          setShowAccListErr(true);
        }
        setAccountLoading(false);
      });
  }

  function getManagerEmplTrainingInfo() {
    axios
      .get(`/api/people/${user.employeeId}/reports`)
      .then((response) => {
        setTrainingInfo(response.data.reports ? response.data.reports : []);
        setTrainingLoading(false);
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          setErrorMessageTraining(`Training Widget: ${e.message}`);
          setShowTrainingErr(true);
        }
        setTrainingLoading(false);
      });
  }

  function getIndivTrainingInfo() {
    axios
      .get(`/api/people/${user.employeeId}/training`)
      .then((response) => {
        setTrainingInfo(response.data[0] ? response.data[0] : []);
        setTrainingLoading(false);
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          setErrorMessageTraining(`Training Widget: ${e.message}`);
          setShowTrainingErr(true);
        }
        setTrainingLoading(false);
      });
  }

  function getAwsTrainingInfo() {
    axios
      .get(`/api/people/${user.employeeId}/awstraining`)
      .then((response) => {
        setAwsTrainingInfo(response.data[0] ? response.data[0] : []);
        setTrainingLoading(false);
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          setErrorMessageTraining(`Training Widget: ${e.message}`);
          setShowTrainingErr(true);
        }
        setTrainingLoading(false);
      });
  }

  function getAzureTrainingInfo() {
    setTrainingLoading(true);
    axios
      .get(`/api/people/${user.employeeId}/azuretraining`)
      .then((response) => {
        setAzureTrainingInfo(response.data[0] ? response.data[0] : []);
        setTrainingLoading(false);
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          setErrorMessageTraining(`Training Widget: ${e.message}`);
          setShowTrainingErr(true);
        }
        setTrainingLoading(false);
      });
  }

  function getTrainingInfo() {
    // Check if user logged in is manager
    setTrainingLoading(true);
    axios
      .get(`/api/lookup/supervisors?q=${userFullName}`)
      .then((response) => {
        const isMng = Object.keys(response.data).length > 0;
        setIsManager(isMng);
        if (isMng) {
          getManagerEmplTrainingInfo();
        } else {
          getIndivTrainingInfo();
          getAwsTrainingInfo();
          getAzureTrainingInfo();
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          setErrorMessageTraining(`Training Widget: ${e.message}`);
          setShowTrainingErr(true);
        }
        setTrainingLoading(false);
      });
  }

  function getAwsHealthEvents() {
    axios
      .get('/api/Vendor/GetHealthEvents')
      .then((response) => {
        setAwsHealthEventList(response.data);
        setAWSHealthLoading(false);
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          setErrorMessageAWSHealth(`Aws Health Widget: ${e.message}`);
          setShowAwsHealthErr(true);
        }
        setAWSHealthLoading(false);
      });
  }

  useEffect(() => {
    setSelectedMenu('home');
    getAccountSummary();
    getAccountListInfo();
    getTrainingInfo();
    getAwsHealthEvents();
  }, []);

  return (
    <>
      <div>
        {loading || trainingLoading || accountLoading || aWSHealthLoading ? (
          <Loader />
        ) : (
          <>
            {showToast ? <Toast /> : '' }
            {/* Account Key Metrics Widget Render */}
            {showAccSumErr ? (
              <Alert
                message={errorMessageAccountSummry}
                alertClass="light-danger"
                onClose={() => setShowAccSumErr(false)}
              />
            ) : (
              !errorMessageAccountSummry && (
                <div className="row mb-3">
                  {/* <!-- Last Month Spend Card & Current Month Spend Estimate --> */}
                  <AccountScoreCard
                    name="Cloud Usage Framework"
                    value={accountSummary.cloudUsageFrameworkScore}
                  />
                  <AccountScoreCard
                    name="Upcoming new rules for CUF"
                    value={accountSummary.newCloudUsageFrameworkScore}
                  />

                  {/* <!-- Last Month Spend Card & Current Month Spend Estimate --> */}
                  <AccountSpendCard
                    name="Last Month Spend"
                    value={accountSummary.previousMonthCost}
                  />
                  <AccountSpendCard
                    name="Current Month Spend Estimate"
                    value={accountSummary.estimatedMonthCost}
                    status={accountSummary.previousMonthCost < accountSummary.estimatedMonthCost}
                  />
                </div>
              )
            )}
            <div className="row">
              {/* Account List Widget Render */}
              {showAccListError ? (
                <div className="col-lg-6 gutter-b">
                  <Alert
                    message={errorMessageAccountList}
                    alertClass="light-danger"
                    onClose={() => setShowAccListErr(false)}
                  />
                </div>
              ) : (
                !errorMessageAccountList && (
                  <AccountWidget
                    accountList={accountListInfo}
                    accountType={accountType}
                    user={user}
                  />
                )
              )}

              {/* <!-- Training Widget Render --> */}
              {showTrainingErr ? (
                <div className="col-lg-6 gutter-b">
                  <Alert
                    message={errorMessageTraining}
                    alertClass="light-danger"
                    onClose={() => setShowTrainingErr(false)}
                  />
                </div>
              ) : (
                !errorMessageTraining && (
                  <>
                    {isManager ? (
                      <>
                        <TrainingManagerWidget trainingInfo={trainingInfo} />
                      </>
                    ) : (
                      <>
                        <TrainingIndividualWidget
                          user={user}
                          trainingInfo={trainingInfo}
                          awsTrainingInfo={awsTrainingInfo}
                          azureTrainingInfo={azureTrainingInfo}
                        />
                      </>
                    )}
                  </>
                )
              )}
            </div>

            {/* Aws & Azure -this-week Series */}
            <div className="row">
              <AwsAzureWidget />
            </div>

            {/* Aws Health Widget */}
            {showAwsHealthErr ? (
              <Alert
                message={errorMessageAWSHealth}
                alertClass="light-danger"
                onClose={() => setShowAwsHealthErr(false)}
              />
            ) : (
              !errorMessageAWSHealth && (
                <>
                  {awsHealthEventList && (
                    <div className="row gutter-b">
                      <AwsHealthEventWidget
                        awsHealthEventList={awsHealthEventList}
                      />
                    </div>
                  )}
                </>
              )
            )}
            {/* Network Widget */}
            {accountType === 'own' && (
              <div className="row gutter-b">
                <NetworkCard user={user} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Home;
